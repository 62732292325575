import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 303.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,303.000000) scale(0.100000,-0.100000)">
         

         <path d="M0 3020 c0 -7 507 -10 1500 -10 993 0 1500 3 1500 10 0 7 -507 10
-1500 10 -993 0 -1500 -3 -1500 -10z"/>
<path d="M1366 1675 c-55 -22 -106 -59 -106 -76 0 -5 20 -43 45 -85 25 -41 45
-79 45 -82 0 -4 -34 -5 -76 -4 l-77 4 7 -33 c20 -101 83 -152 255 -205 152
-47 182 -73 125 -110 -23 -15 -34 -16 -101 -6 -45 7 -102 25 -142 45 -36 17
-69 33 -72 35 -3 1 -22 -23 -41 -55 -19 -32 -42 -70 -52 -85 l-17 -26 83 -41
c108 -53 206 -74 323 -68 188 9 288 98 288 257 0 132 -54 184 -266 255 -124
42 -151 60 -131 93 9 13 25 17 75 16 58 -1 96 -12 206 -60 10 -5 49 60 101
167 6 12 -134 67 -199 78 -110 19 -206 14 -273 -14z m230 -8 c4 -4 19 -7 32
-7 14 0 42 -5 61 -12 100 -32 118 -49 85 -82 -8 -9 -13 -16 -9 -16 3 0 -2 -14
-11 -31 -18 -35 -23 -35 -114 0 -75 29 -161 28 -195 -1 -27 -24 -33 -62 -11
-75 7 -4 19 -32 25 -60 6 -29 22 -79 35 -112 14 -34 19 -58 13 -56 -7 2 -37
11 -67 20 -30 9 -71 24 -90 32 -36 16 -89 71 -102 104 -7 19 7 23 84 22 10 0
16 3 13 5 -3 3 3 14 12 24 16 18 15 23 -21 92 -22 41 -36 81 -33 89 7 20 57
46 117 63 48 13 163 14 176 1z m-43 -291 c18 -8 66 -26 107 -40 41 -14 76 -30
78 -36 2 -5 8 -10 14 -10 17 0 60 -51 54 -65 -2 -7 2 -16 10 -19 26 -10 10
-186 -18 -186 -4 0 -8 -8 -8 -17 0 -9 -3 -13 -6 -10 -4 3 -15 -5 -26 -18 -11
-14 -28 -25 -38 -25 -10 0 -20 -5 -22 -11 -3 -10 -26 -16 -100 -23 -21 -3 -41
-7 -45 -9 -18 -11 -104 -17 -98 -7 3 5 12 10 18 10 30 0 91 67 119 129 17 35
31 72 33 81 4 22 -17 47 -49 60 -34 12 -65 80 -73 156 -6 60 -2 64 50 40z
m-243 -265 c0 -9 69 -43 120 -59 8 -2 37 -6 63 -9 47 -5 48 -6 42 -32 -3 -14
-19 -38 -36 -53 -45 -42 -94 -39 -236 15 -29 10 -53 23 -53 28 0 19 28 75 48
95 18 19 52 29 52 15z"/>
<path d="M1042 1650 l-23 -40 51 0 51 0 -23 40 c-13 22 -25 40 -28 40 -3 0
-15 -18 -28 -40z"/>
<path d="M1159 1608 c-12 -24 -37 -67 -55 -95 l-34 -53 111 0 c61 0 109 3 107
8 -11 26 -100 182 -104 182 -2 0 -13 -19 -25 -42z m43 -55 c22 -20 23 -30 3
-49 -18 -18 -55 -10 -55 12 0 18 18 54 27 54 4 0 15 -7 25 -17z"/>
<path d="M995 1460 c-20 -39 -20 -38 20 -38 19 0 35 2 35 4 0 9 -30 64 -35 64
-2 0 -11 -13 -20 -30z"/>
<path d="M793 823 c-7 -2 -13 -15 -13 -29 0 -18 9 -27 37 -39 28 -12 34 -18
25 -27 -10 -10 -17 -10 -32 0 -30 19 -43 15 -30 -8 12 -23 59 -27 78 -8 23 23
13 48 -24 64 -25 10 -33 19 -26 26 6 6 16 5 30 -3 25 -16 40 -5 22 16 -12 15
-42 18 -67 8z"/>
<path d="M1469 814 c-18 -22 -1 -46 40 -57 18 -4 31 -13 28 -20 -5 -17 -48
-21 -58 -6 -4 8 -11 10 -15 6 -13 -12 18 -37 47 -37 57 0 57 62 -1 75 -17 4
-30 13 -30 21 0 17 47 19 53 2 2 -7 8 -10 12 -6 12 12 -15 38 -40 38 -12 0
-28 -7 -36 -16z"/>
<path d="M1670 767 l0 -64 32 1 c26 1 34 7 42 28 8 22 6 32 -9 48 -11 12 -25
18 -36 15 -14 -5 -16 -1 -12 15 3 11 0 20 -6 20 -7 0 -11 -23 -11 -63z m58
-15 c2 -13 -3 -25 -13 -28 -23 -9 -38 10 -30 36 9 27 39 22 43 -8z"/>
<path d="M894 787 c-3 -8 -4 -30 -2 -49 3 -31 6 -33 41 -35 l37 -1 0 49 c0 57
-17 66 -22 12 -2 -26 -8 -38 -18 -38 -11 0 -14 9 -13 32 3 34 -13 54 -23 30z"/>
<path d="M1006 784 c-19 -18 -21 -55 -4 -72 17 -17 58 -15 65 3 8 22 -4 30
-21 16 -27 -23 -47 6 -27 38 7 12 13 12 24 3 18 -15 31 -3 16 15 -15 17 -34
17 -53 -3z"/>
<path d="M1095 780 c-26 -29 -10 -74 28 -78 27 -3 48 21 32 36 -4 4 -10 1 -12
-5 -3 -7 -11 -13 -19 -13 -11 0 -14 8 -12 28 2 22 7 26 26 24 25 -4 30 13 6
22 -24 9 -29 8 -49 -14z"/>
<path d="M1192 788 c-7 -7 -12 -27 -12 -45 0 -26 5 -34 24 -39 26 -6 46 1 46
17 0 5 -11 7 -25 3 -15 -4 -25 -2 -25 5 0 6 14 11 30 11 34 0 36 6 14 38 -17
25 -34 28 -52 10z"/>
<path d="M1282 788 c-20 -20 -14 -35 18 -47 39 -13 39 -25 -1 -17 -22 4 -29 2
-27 -7 7 -18 53 -20 68 -3 10 13 10 19 0 31 -7 8 -18 15 -26 15 -7 0 -16 5
-20 11 -4 8 4 9 27 5 32 -7 33 -6 15 9 -24 18 -38 19 -54 3z"/>
<path d="M1375 784 c-12 -12 -13 -19 -4 -30 6 -8 17 -14 25 -14 7 0 16 -5 20
-11 4 -8 -4 -9 -27 -4 -31 7 -32 6 -20 -9 20 -24 65 -17 69 11 3 17 -3 24 -22
29 -14 3 -26 10 -26 15 0 5 12 6 28 3 24 -7 26 -6 13 9 -17 21 -35 22 -56 1z"/>
<path d="M1578 794 c-10 -10 -10 -65 0 -81 4 -6 22 -11 40 -11 31 1 32 2 32
44 0 54 -18 67 -22 17 -2 -26 -8 -39 -20 -41 -13 -3 -17 6 -20 38 -2 23 -6 38
-10 34z"/>
<path d="M1816 784 c-19 -19 -20 -44 -3 -68 17 -24 61 -18 66 9 3 17 1 18 -13
6 -22 -19 -48 -6 -44 21 2 18 9 23 31 21 24 -2 27 0 17 13 -16 18 -35 18 -54
-2z"/>
<path d="M1901 786 c-17 -20 -10 -67 12 -80 24 -15 51 4 55 40 6 43 -41 71
-67 40z m47 -39 c3 -21 -1 -27 -17 -27 -23 0 -32 18 -19 43 13 25 32 16 36
-16z"/>
<path d="M1990 747 c0 -26 4 -46 9 -43 4 3 7 16 4 28 -4 28 11 53 26 44 6 -4
11 -23 11 -42 0 -19 5 -34 11 -34 6 0 10 12 9 28 -3 44 -1 52 15 52 11 0 15
-11 15 -40 0 -22 5 -40 10 -40 6 0 10 17 10 39 0 44 -22 70 -43 52 -8 -6 -17
-7 -21 -3 -4 4 -19 7 -32 7 -22 1 -24 -3 -24 -48z"/>
<path d="M2188 793 c-14 -3 -18 -15 -18 -49 0 -54 18 -59 22 -6 2 25 8 37 18
37 10 0 16 -12 18 -37 4 -50 22 -49 22 0 0 53 -16 67 -62 55z"/>
<path d="M2275 783 c-8 -8 -15 -23 -15 -33 0 -24 29 -53 46 -46 16 6 19 -10 4
-19 -6 -4 -17 -2 -25 5 -8 7 -15 7 -19 1 -11 -17 31 -33 54 -21 16 9 20 21 20
68 0 54 -1 57 -25 58 -14 1 -32 -5 -40 -13z m43 -36 c2 -19 -1 -27 -13 -27
-19 0 -30 24 -21 46 9 23 31 11 34 -19z"/>
<path d="M747 3 c414 -2 1092 -2 1505 0 414 1 76 2 -752 2 -828 0 -1166 -1
-753 -2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
